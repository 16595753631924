import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'



export const accountData = [
    { name: "حسابى", url: "" },
    { name: "طلباتى", url: "" },
    { name: "سلة المشتريات", url: "" },
    { name: "المفضلة", url: "" },
];


export const importantLinksData = [
    { name: "من نحن", url: "" },
    { name: "ساسية الخصوصية", url: "" },
    { name: "الشروط والاحكام", url: "" },
    { name: "الدعم الفنى", url: "" },
];


export const contactUsData = [
    { name: "واتساب", paragraph: "009612345678932", url: "" },
    { name: "موبايل", paragraph: "009612345678932", url: "" },
    { name: "البريد", paragraph: "https://salla.sa", url: "" },
];

// This Function Will Display the List of the Links acsept arr of links and display it to screen
export const renderLists = (arr, isIncludeParagraph) => {
    return arr.map((item , index) => {
        return (
            <li key={index} className='flex items-center mb-2 gray-text transition hover:!text-[var(--min-color)] hover:!ps-3'>

                <FontAwesomeIcon icon={faAngleDoubleLeft} className='me-2'></FontAwesomeIcon>

                <div>

                    <Link to={''}>{item.name}</Link>

                    {isIncludeParagraph && <p className='gray-text mt-1 ms-5'>{item.paragraph}</p>}

                </div>

            </li>
        )
    })
}