import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';

export default function AddToFavoriteIcon() {

    const [isFavorite, setIsFavorite] = useState(false);

    const toast = useRef(null);

    const showSuccess = (message, severity) => {

        toast.current.show({ severity, summary: severity, detail: message, life: 3000 });

    }

    const handleFavoriteButton = () => {

        setIsFavorite(!isFavorite);

        // i put ! before the favorite variable because useState in react is async so, we need to handel it 
        if (!isFavorite) {

            showSuccess('Product Added To Favorite Successfully', 'success');

        } else {

            showSuccess('Product Removed From Favorite Successfully', 'info');

        }
    }


    return (

        <Fragment>

            <button className='flex items-center justify-center rounded-[5px] border ms-3 p-4 text-[var(--gray-color)]' onClick={_ => handleFavoriteButton()}>

                <FontAwesomeIcon icon={faHeart} className={`${isFavorite ? "text-red-500" : ''}`}></FontAwesomeIcon>

            </button>

            <Toast ref={toast} />

        </Fragment>
    )
}
