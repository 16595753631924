import PropTypes from 'prop-types';

export default function ArticleCard(props) {

    const { imageSrc, date } = props;
    return (
        <div className='text-center'>

            {/* Image container */}
            <div className='relative'>

                {/* Card image */}
                <img src={require(`../../../assets/images/${imageSrc}.png`)} alt='' className='rounded-[5px] w-full object-cover h-[260px]' />

                {/* Date label  */}
                <div className='bg-white text-center p-1 px-3 absolute right-3 top-3 rounded-[2px]'>

                    <h6 className='text-[14px]'>{date}</h6>
                    <p className='gray-text !text-[13px]'>ينانير</p>

                </div>

            </div>

            {/* Title */}
            <h1 className='font-medium text-[20px] mt-6 mb-2'>مدى تأثير التكنولوجيا على حياة الإنسان</h1>

            {/* Description */}
            <p className='gray-text px-5'>لكي تنجح في أعمال التجارة الإلكترونية، تحتاج إلى بيع المنتجات المطلوبة، إذا لم يكن لديك فكرة، سنشارك في هذه المقالة قائمة أنواع المنتجات</p>

        </div>
    )
}

// Add PropTypes validation
ArticleCard.propTypes = {
    imageSrc: PropTypes.string,
    date: PropTypes.number,
}