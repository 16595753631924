// This Component Will Render Card Of Commerical Brand
export default function CommercialBrandsCard({ imageSrc }) {
    return (
        <div className='border-2 rounded-[5px] flex items-center justify-center p-2'>

            {/* Card Background Image */}
            <img src={require(`../../../assets/images/${imageSrc}.png`)} alt='' className='select-none' />

        </div>
    )
}
