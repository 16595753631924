export const phones = [
    { name: 'ايفون', href: '' },
    { name: 'سامسونج', href: '' },
    { name: 'نوكيا', href: '' },
];
export const electronics = [
    { name: 'شاشات كمبيوتر', href: '' },
    { name: 'شاشات تلفزيون', href: '' },
    { name: 'إكسسوارات', href: '' },
    { name: 'كاميرات رقمية', href: '' },
    { name: 'أدوات تصوير', href: '' },
    { name: 'عدسات نيكون', href: '' },
    { name: 'شاشات استيراد', href: '' },
];
