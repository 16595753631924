// Import necessary dependencies
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHref } from 'react-router-dom';

// Define the Breadcrumb component
export default function Breadcrumb() {

    const arbLangLocation = { home: "الرئيسية", products: "كل المنتجات", ['products-details']: "تفاصيل المنتج", cart: "تفاصيل المنتج" }
    // Set initial state of the `getLocation` variable to the current window location pathname
    const [getLocation, setLocation] = useState(window.location.pathname);

    // Get the current location using the `useHref` hook
    const location = useHref();

    // Split the `getLocation` variable into an array called `improvePathname`
    const improvePathname = getLocation.split('/');


    // Update the `getLocation` state variable when the `location` variable changes
    useEffect(() => {
        setLocation(window.location.pathname);
    }, [location]);

    // Render the breadcrumb navigation and login section
    return (
        <>
            {
                location !== "/"
                    ?
                    <div className="flex h-[72px] px-[60px] shadow-sm">

                        <div className="container flex items-center justify-between w-full">

                            <div className="text-[14px] font-[600]">

                                {/* Render a clickable link to the home page */}
                                {location != "/" && <Link to="/" className='text-[var(--min-color)]'>{arbLangLocation.home}</Link>}

                                {/* Render pathnames using the `improvePathname` variable */}
                                {improvePathname.map((path, index) => {

                                    // If the current `path` element is not empty:
                                    return path.length ? (

                                        // Wrap the JSX element in a <Fragment> component to group elements together
                                        <Fragment key={index}>

                                            {/* Add a forward slash with horizontal padding */}
                                            <span className="px-1 text-[var(--min-color)]">/</span>

                                            {/* Create a clickable link to the URL constructed by concatenating a forward slash with the current `path` element */}
                                            <Link to={path === (improvePathname[improvePathname.length - 1]) ? window.location.path : "/" + path} className={`${index === improvePathname.length - 1 ? "text-[#2B5D5F]" : "text-[var(--min-color)]"} text-[12px] sm:text-[16px] inline-block`}>

                                                {/* Replace any hyphens in the `path` element with spaces */}
                                                {arbLangLocation[path] || path}

                                            </Link>

                                        </Fragment>

                                    ) : (
                                        // If the current `path` element is empty, return null
                                        null
                                    );

                                })}

                            </div>

                        </div>

                    </div>
                    :
                    null
            }
        </>
    )
}