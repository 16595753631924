import { Bars3Icon } from "@heroicons/react/24/outline";
import HeaderLogo from "../HeaderLogo";
import { Popover } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Auth from "../Auth";
import Cart from "../Cart";


export default function DesktopHeader({ setMobileMenuOpen }) {


    return (

        <nav className="grid grid-cols-12 gap-5 h-full" aria-label="Global">

            <div className="hidden lg:flex col-span-2 xl:col-span-1">
                <HeaderLogo />
            </div>

            {/* Mobile Design */}
            <div className="flex items-center justify-between col-span-12 lg:hidden">

                <div className='flex items-center'>

                    <button type="button" className="inline-flex items-center justify-center rounded-md text-[var(--min-color)] mx-2 " onClick={() => setMobileMenuOpen(true)}>

                        <span className="sr-only">Open main menu</span>

                        <Bars3Icon className="h-8 w-8" aria-hidden="true" />

                    </button>

                    <HeaderLogo />

                </div>

                <div className="flex items-center">

                    {/* Auth */}
                    <Auth />

                    {/* Cart */}
                    <Cart />

                </div>

            </div>

            <Popover.Group className="hidden lg:flex lg:gap-x-6 xl:gap-x-12 lg:justify-center lg:items-center col-span-6 xl:col-span-8">

                {/* Search */}
                <div className='px-3 relative w-full'>

                    <input type='text' className='border border-[var(--gray-color)] rounded-[5px] p-3 w-full' placeholder='ابحث عما تريد' />

                    <FontAwesomeIcon icon={faMagnifyingGlass} className='absolute left-[25px] top-[50%] translate-y-[-50%] text-[var(--gray-color)]'></FontAwesomeIcon>

                </div>

            </Popover.Group>

            <Popover.Group className="hidden lg:flex lg:gap-x-6 xl:gap-x-12 lg:justify-between lg:items-center col-span-4 xl:col-span-3">

                {/* Auth */}
                <Auth />

                {/* Cart */}
                <Cart />

            </Popover.Group>



        </nav>


    )
}
