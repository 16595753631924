import './assets/styles/App.css';
import './assets/styles/responsive.css';
import { Header, Footer, Navbar, Breadcrumb } from './views/layouts';
import routes from './routes/routes';
import { ScrollReset, UpButton } from './views/components';
import { useState } from 'react';


function App() {

  const [activeMnueLang, setActiveMnueLang] = useState(false);

  return (

    <div className="App">

      <Navbar activeMnueLang={activeMnueLang} setActiveMnueLang={setActiveMnueLang} />

      <Header activeMnueLang={activeMnueLang} setActiveMnueLang={setActiveMnueLang} />

      <Breadcrumb />

      <ScrollReset />

      <UpButton />

      {routes()}

      <Footer></Footer>

    </div>

  );
}

export default App;
