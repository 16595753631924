import { Link } from 'react-router-dom'
import logo from '../../../../../assets/images/logo.png'
export default function HeaderLogo() {
    return (

        <div className="col-span-1 lg:flex lg:gap-x-12 lg:justify-center lg:items-center">

            <Link to="/">

                <span className="sr-only">Your Company</span>

                <img src={logo} className='min-w-[103px] max-w-[103px]' />

            </Link>

        </div>

    )
}
