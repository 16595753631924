import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { DesktopHeader, MobileMenuActions, MobileProductDropdown } from './components';
import { phones, electronics } from './headerData'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header({ setActiveMnueLang }) {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const choosedLang = localStorage.getItem('lang') === "Arabic" ? 'العربيه' : localStorage.getItem('lang');


    return (

        <header className="bg-white relative container !py-10">

            <DesktopHeader setMobileMenuOpen={setMobileMenuOpen} />

            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>


                <Dialog.Panel className="fixed inset-y-0 right-0 z-[500000000000000000000] w-[250px] transition sm:w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">


                    <MobileMenuActions setMobileMenuOpen={setMobileMenuOpen} />

                    <div className="mt-6 flow-root">

                        <div className="-my-6 divide-y divide-gray-500/10">

                            <div className="space-y-2 py-6">

                                <MobileProductDropdown products={phones} title={'موبيلات'} classNames={classNames} />
                                <MobileProductDropdown products={electronics} title={'إلكترونيات'} classNames={classNames} />
                                <MobileProductDropdown products={electronics} title={'ساعات رقمية'} classNames={classNames} />
                                <MobileProductDropdown products={electronics} title={'إضاءة مكتبية'} classNames={classNames} />

                                <div className='flex items-center justify-between !my-10'>

                                    <div className='flex items-center justify-center w-[48%] relative cursor-pointer bg-[#F8F8F8] p-2' onClick={e => {
                                        // To ensure cansel Click on the parent element
                                        e.stopPropagation();
                                        setActiveMnueLang(true);
                                    }}>

                                        <p className='gray-text me-3'>{choosedLang || 'العربية - رس'}</p>


                                        <img src={`https://github.com/ProNabowy/countries_Flags/blob/main/${localStorage.getItem('lang') || 'Arabic'}.png?raw=true`} className='w-[25px] object-cover' />

                                    </div>

                                    <div className='flex items-center justify-center w-[48%] relative cursor-pointer bg-[#F8F8F8] p-2 font-medium text-[14px]'>
                                        الرياض
                                    </div>

                                </div>


                            </div>


                        </div>

                    </div>

                </Dialog.Panel>


            </Dialog>

        </header>
    )
}
