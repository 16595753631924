import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function MobileProductDropdown({ products, title, classNames }) {
    return (
        <Disclosure as="div" className="-mx-3 transition">

            {({ open }) => (

                <>

                    <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">

                        {title}

                        <ChevronDownIcon className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none transition')} aria-hidden="true" />

                    </Disclosure.Button>

                    <Disclosure.Panel className="mt-2 space-y-2">

                        {[...products].map((item) => (

                            <Disclosure.Button key={item.name} as="span" className="block rounded-lg p-3 py-2 ps-10 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50" >

                                <Link to={item.href}>

                                    {item.name}

                                </Link>

                            </Disclosure.Button>

                        ))}

                    </Disclosure.Panel>

                </>

            )}

        </Disclosure>
    )
}
