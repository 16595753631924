// importing require modules
import { Rating } from '../../components'

export default function OpinionsCard() {
    return (
        <div className='border-2 p-8 rounded-[7px]'>

            <div className='flex items-center justify-between mb-5'>

                {/* Render rating  */}
                <Rating rate={4} isDisabled={true}></Rating>

                <div>

                    <img src={require('../../../assets/images/quotes.png')} />

                </div>

            </div>

            {/* Opinion text */}
            <p className='font-medium leading-7 mb-5'>
                استخدمنا سلة قبل أربع سنوات ثم غادرناها قبل أن يكون فيها خيارات ترقية مدفوعة، خسرنا مبالغ كبيرة جدا في عمل متجر وتعبنا جدا في التعامل مع المبرمجين . ثم عدنا لمنصة سلة ووجدنا تطورا هائلاً وعملا مميزاً
            </p>

            {/* Author details */}
            <div className='flex items-center'>

                {/* Author image */}
                <img src={require('../../../assets/images/nabowy.jpg')} alt='' className='w-[50px] h-[50px] rounded-full me-5' />

                <div>

                    {/* Author name */}
                    <h2 className='font-medium'>محمود ابراهيم</h2>

                    {/* Author title */}
                    <p className='gray-text'>مهندس برمجيات لدى شركة سلة</p>


                </div>

            </div>

        </div>
    )
}
