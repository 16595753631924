import React, { useState } from "react";
import { Rating } from "primereact/rating";
import PropTypes from 'prop-types';

// Will Rander The List Of Rate Stars 
export default function RatingStar(props) {

    const { rate, isDisabled } = props

    const [value, setValue] = useState(rate || 4);

    return (
        <div className="card flex justify-content-center">
            <Rating
                value={value}
                disabled={isDisabled ? true : null}
                onChange={(e) => setValue(e.value)}
                cancel={false}
            />
        </div>
    );
}


RatingStar.propTypes = {
    rate: PropTypes.number,
    isDisabled: PropTypes.bool,
}