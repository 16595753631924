// importing require modules
import './loader.css';

// this component will render the gloable Loader of the appliaction
export default function Loader() {
    return (
        <div className='fixed left-0 top-0 w-full h-full bg-[#03002e] flex items-center justify-center'>
            <div>
                <span className="loader"></span>
            </div>
        </div>
    )
}
