// importing require modules
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { AddToFavoriteIcon, AddToCart } from './components';


// Will Render The Product Card
export default function ProductCard(props) {

    const { imageName, classNames, hasOffer, hasDiscount } = props;

    return (
        <div className={`${classNames} border rounded-[5px] overflow-hidden relative`}>

            <Link to={'/products/products-details'}>
                <img src={require(`./assets/images/${imageName}.png`)} alt='' className='w-full max-w-full object-fill h-[228px] mb-3' />
            </Link>

            {/* If Product Has Offer That's Render This Div */}
            {hasOffer && <div className='new-offer'>جديد</div>}

            {/* If Discount Has Offer That's Render This Div */}
            {hasDiscount && <div className='discount'>خصم 25%</div>}


            <div className='p-3'>

                <p className='text-[var(--min-color)] mb-3 ms-5'>ساعات</p>

                <h4 className='font-medium mb-3'>ساعة ذكية جديدة من سلسلة 8</h4>

                <p className='gray-text mb-3 ms-3'>سوار رياضي اسود - عادي.</p>

                <h4 className='text-[var(--danger-color)] font-medium'>

                    <span>250.00 رس</span>
                    <span className='old-price ms-3'>350.00 ر.س</span>

                </h4>

                <div className='flex items-center justify-between mb-2 mt-5'>

                    <AddToCart />

                    <AddToFavoriteIcon />


                </div>

            </div>

        </div>
    )
}

// Add PropTypes validation
ProductCard.propTypes = {
    imageName: PropTypes.string,
    classNames: PropTypes.string,
    hasOffer: PropTypes.bool,
    hasDiscount: PropTypes.bool,
}

