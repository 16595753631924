import { data, gridItem } from './data'
import { DataTable } from '../../components'

// That's Will Display All Of Languages At The Table 
export default function Languages({ activeMnueLang }) {

    return (

        <div className={`absolute left-3 bottom-0 w-full transition `} style={{ direction: "ltr" }} onClick={(e) => e.stopPropagation()}>

            <div className={`lang-mnue overflow-hidden absolute bg-[white] left-0 z-[99999] p-5 bg-red w-full shadow-lg  ${activeMnueLang ? "active" : ""}`}>

                <div className={`container lg:px-32`}>

                    <div className='grid grid-cols-12 gap-3 lg:gap-5 overflow-hidden'>

                        <div className='col-span-12'>

                            <DataTable data={data} countOfRows={40} gridItem={gridItem}></DataTable>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}