import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Auth() {
    return (
        <div className='flex items-center mx-4 w-[49%]'>

            <div className='w-[40px] h-[40px] me-1 rounded-full flex items-center justify-center bg-[--off-white]'>

                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>

            </div>

            <div className='hidden sm:block'>

                <p className='gray-text !text-[12px]'>مرحبا بك</p>
                <h5 className='gray-text !text-black !text-[12px]'>تسجيل دخول</h5>

            </div>

        </div>
    )
}
