import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Cart() {
    return (
        <div className='flex items-center w-[49%]'>

            <Link to={'cart'} className='w-[40px] h-[40px] me-1 rounded-full flex items-center justify-center bg-[--off-white]'>

                <FontAwesomeIcon icon={faCartPlus} />

            </Link>

            <div className="hidden sm:block">

                <p className='gray-text !text-[12px]'>سلة المشتريات</p>
                <h5 className='gray-text !text-[12px] !text-black'>1,200 رس</h5>

            </div>

        </div>
    )
}
