import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

// That's Will Render The Head Of Page Footer
export default function JoinWithUs() {
    return (
        <div className='bg-[--off-white] p-6'>

            <div className='container grid grid-cols-12 gap-8'>

                <div className='flex items-center col-span-12 xl:col-span-3'>

                    <div className='me-2 w-[50px] h-[50px] rounded-full bg-[#D6F8F0] flex items-center justify-center'>

                        <FontAwesomeIcon icon={faEnvelope} className='text-[20px] text-[var(--min-color)]'></FontAwesomeIcon>

                    </div>

                    <div>

                        <h2 className='font-medium mb-1'>الاشتراك فى النشرة البريدية</h2>

                        <p className='gray-text max-w-[80%] sm:max-w-full'>انضم الآن واحصل على خصم 10٪ على مشترياتك التالية!</p>

                    </div>

                </div>

                <div className='col-span-12 lg:col-span-8 xl:col-span-6'>

                    <p className='gray-text mb-2'>يمكنك إلغاء الاشتراك في أي لحظة</p>

                    <div className='flex items-center'>

                        <input type='email' className='flex-1 max-w-[60%] sm:max-w-[100%] border p-3 me-3 rounded-[5px] placeholder:text-[var(--gray-color)]' placeholder='ادخل البريد الالكترونى' />

                        <button className='min-btn !p-3'>إشتراك</button>

                    </div>

                </div>

                <div className='col-span-12 lg:col-span-4 xl:col-span-3'>

                    <h2 className='font-medium mb-3'>تطبيقات الجوال</h2>

                    <div className='flex items-center'>

                        <img src={require(`../../../../../assets/images/apple.png`)} alt='' className='me-3' />
                        <img src={require(`../../../../../assets/images/apple.png`)} alt='' className='me-3' />

                    </div>

                </div>

            </div>

        </div>
    )
}
