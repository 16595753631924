import { Toast } from 'primereact/toast';
import React, { Fragment, useRef, useState } from 'react'
import './index.css';
import { handelAnimationCartBtn } from './data';

export default function AddToCart() {

    const [isAddToCart, setIsAddToCart] = useState(false);

    const toast = useRef(null);

    const showSuccess = (message, severity) => {

        toast.current.show({ severity, summary: severity, detail: message, life: 3000 });

    }


    return (
        <Fragment>

            <button className="cart-button flex-1" onClick={(e) => handelAnimationCartBtn(e, isAddToCart, setIsAddToCart, showSuccess)}>
                <span>Add to cart</span>

                <div className="cart">

                    <svg viewBox="0 0 36 26">

                        <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>

                        <polyline points="15 13.5 17 15.5 22 10.5"></polyline>

                    </svg>

                </div>
            </button>

            <Toast ref={toast} />

        </Fragment>
    )
}