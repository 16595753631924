
const handleAddToCartButton = ( isAddToCart ,setIsAddToCart , showSuccess) => {

    setIsAddToCart(true);

    // i put ! before the favorite variable because useState in react is async so, we need to handel it 
    if (!isAddToCart) {

        showSuccess(`Product successfully added to cart. Enjoy!`, 'success');

    } else {

        showSuccess(`Product already in cart. Happy shopping!`, 'warn');

    }
}

export const handelAnimationCartBtn = (e, isAddToCart, setIsAddToCart, showSuccess) => {

    const targetElement = e.target.closest(".cart-button");

    if (targetElement && !targetElement.classList.contains("loading")) {

        const toggle = e.target.tagName === "SPAN";

        const elementToToggle = toggle ? targetElement : e.target;

        elementToToggle.classList.add("loading");

        setTimeout(() => {

            elementToToggle.classList.remove("loading");

        }, 3700);
    }

    handleAddToCartButton(isAddToCart, setIsAddToCart, showSuccess);

}